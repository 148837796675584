import React from "react"

import { withFooter } from "./withFooter"
import { Bg, Top, Left, Right, Bottom, InnerWrapper, LogoWrapper } from "./FooterStyles"
import { NavigationFooter } from "../Navigation/Footer/NavigationFooter"
import { SellingPoints } from "./SellingPoints/SellingPoints"
import { Subscribe } from "../Subscribe/Subscribe"
import { KlaviyoFooter } from "../KlaviyoFooter/KlaviyoFooter"
import { SocialMedia } from "../SocialMedia/SocialMedia"
import { NavigationTerms } from "../Navigation/Terms/NavigationTerms"
import { PaymentIcons } from "./PaymentIcons/PaymentIcons"
import { Brand } from "./Brand/Brand"
import { SKidmanLogo } from "./SKidman"

export const Footer = withFooter(
  ({ title, isMobile }): JSX.Element => (
    <>
      <SellingPoints />
      <Bg>
        <Top>
          <Left>
            <LogoWrapper>
              <Brand layout={"desktop"} />
              <SKidmanLogo />
            </LogoWrapper>
            <KlaviyoFooter />
            {/* <Subscribe /> */}
          </Left>

          <SocialMedia layout={"mobile"} />

          <Right>
            <InnerWrapper>
              <NavigationFooter />
            </InnerWrapper>
          </Right>
        </Top>
        <Bottom>
          <SocialMedia layout={"desktop"} />
          <NavigationTerms />
          <PaymentIcons layout={"desktop"} />
        </Bottom>
      </Bg>
      <PaymentIcons layout={"mobile"} />
    </>
  )
)
